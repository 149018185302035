import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

const headers = new HttpHeaders()
@Injectable({
  providedIn: 'root'
})
export class MarketService {

  constructor(private https: HttpClient) { }

  /*Market List API Calling */
  getMarketList(Operatorid: string) {
    return this.https.get<any[]>(
      `${environment.apiEndpoint}/market/listall/${encodeURIComponent(Operatorid)}`
    );
  }

  /* Market WeekEndingDates API Calling */
  getMarketWeekendingDates(Lease_Term: string) {
    return this.https.get(
      `${environment.apiEndpoint}/market/weekendingdates/${encodeURIComponent(Lease_Term)}`,
    );
  }

  getOperator() {
    return this.https.get(
      `${environment.apiEndpoint}/operator/useroperator`,
      { headers: headers }
    );
  }

  getLeaseTerm(operatorId: string) {
    return this.https.get(
      `${environment.apiEndpoint}/operator/leaseterm/${encodeURIComponent(operatorId)}`,
      { headers: headers }
    );
  }

  /*3.	Market Compset List API Calling */
  getMarketCompsetList(payloadObject: compsetslist) {
    return this.https.post(
      `${environment.apiEndpoint}/market/listall`,
      payloadObject,
    )
  };

  // Delete
 

  getMarketLeaseinfo(payloadObject: leaseinfo) {
    return this.https.post(
      `${environment.apiEndpoint}/market/leaseinfo`,
      payloadObject,
      { headers: headers }
    );
  }

  getCompsetList(payloadObject: compsetslist) {
    return this.https.post(
      `${environment.apiEndpoint}/compset/listall`,
      payloadObject,
      { headers: headers }
    );
  }

  currentCompsetResponseObject!: any;
  currentCompsetRequestObject!: compsetInfo;
  getCompsetInfo(payloadObject: any) {
    return this.https.post(
      `${environment.apiEndpoint}/compset/leaseinfo`,
      payloadObject,
      { headers: headers }
    );
  }

  getmarketUnitinfo(payloadObject: marketUnitInfor) {
    return this.https.post(
      `${environment.apiEndpoint}/market/unittypeinfo`,
      payloadObject,
      { headers: headers }
    );
  }

  getCompsetUnitinfo(payloadObject: compsetUnitInfor) {
    return this.https.post(
      `${environment.apiEndpoint}/compset/unittypeinfo`,
      payloadObject,
      { headers: headers }
    );
  }

  getoperatorleaseterm(payloadObject: operatorlease) {
    return this.https.get(
      `${environment.apiEndpoint}/operator/useroperatorleaseterm/${encodeURIComponent(payloadObject.User_Id)}`

    )
  }

  getoperatorlistall(payloadObject: operatorlistall) {
    if (!payloadObject.Operator_Id)
      return of([]);
    return this.https.get(
      `${environment.apiEndpoint}/operator/leaseterm/listall/${encodeURIComponent(payloadObject.Operator_Id)}`
    )
  }

  getMarketPreleasetrend(payloadObject: marketleasetrend) {
    return this.https.post(
      `${environment.apiEndpoint}/market/preLeasetrend`,
      payloadObject,
      { headers: headers }
    );
  }

  getCompsetPreleasetrend(payloadObject: leasetrend) {
    return this.https.post(
      `${environment.apiEndpoint}/compset/preleasetrend`,
      payloadObject,
      { headers: headers }
    );
  }
  // Delete
  // getunittypeinfo(payloadObject: leaseinfo) {
  //   return this.https.post(
  //     `${environment.apiEndpoint}/market/unittypeinfo`,
  //     payloadObject,
  //     { headers: headers }
  //   );
  // }

  getMarketUnittypeinfo(payloadObject: marketUnitInfor) {
    return this.https.post(
      `${environment.apiEndpoint}/market/unittypeinfo`,
      payloadObject,
      { headers: headers }
    );
  }
  // getmarketUnitinfo(payloadObject: marketUnitInfor) {
  //   return this.https.post(
  //     `${environment.apiEndpoint}/market/unittypeinfo`,
  //     payloadObject,
  //     { headers: headers }
  //   );
  // }

  getpropertyinformation(payloadObject: propertyinfo) {

    return this.https.post(
      `${environment.apiEndpoint}/market/propertyinformation`,
      payloadObject,
      { headers: headers }
    );
  }

  getamenities() {
    return this.https.get(
      `${environment.apiEndpoint}/market/amenities`,
      { headers: headers }
    );
  }

  updateamenities(payloadObject: updateamenities) {
    return this.https.put(
      `${environment.apiEndpoint}/market/amenities`,
      payloadObject,
      { headers: headers }
    );
  }

  getfloorplaninformation(payloadObject: floorplaninfo) {
    return this.https.post(
      `${environment.apiEndpoint}/market/floorplaninformation`,
      payloadObject,
      { headers: headers }
    );
  }

  getpropertypreleaseoccupancy(payloadObject: occupancy) {
    return this.https.post(
      `${environment.apiEndpoint}/market/propertypreleaseoccupancy`,
      payloadObject,
      { headers: headers }
    );
  }

  updatepropertypreleaseoccupancy(payloadObject: updateoccupancy) {
    return this.https.put(
      `${environment.apiEndpoint}/market/propertypreleaseoccupancy`,
      payloadObject,
      { headers: headers }
    );
  }

  updatepropertydetails(payloadObject: updatepropertydetail) {
    return this.https.put(
      `${environment.apiEndpoint}/market/propertydetails`,
      payloadObject,
      { headers: headers }
    );
  }

  createpropertydetails(payloadObject: createropertydetail) {
    return this.https.post(
      `${environment.apiEndpoint}/market/propertydetails`,
      payloadObject,
      { headers: headers }
    );
  }

  createfloorplandetails(payloadObject: floorplandetail) {
    return this.https.post(
      `${environment.apiEndpoint}/market/floorplandetails`,
      payloadObject,
      { headers: headers }
    );
  }

  updatefloorplandetails(payloadObject: updatefloorplandetail) {
    return this.https.put(
      `${environment.apiEndpoint}/market/floorplandetails`,
      payloadObject,
      { headers: headers }
    );
  }

  getmanagementcompanies() {
    return this.https.get(
      `${environment.apiEndpoint}/market/managementcompany`,

      { headers: headers }
    );
  }

  getMarketByPropertyId(payloadObject: marketbypropertid) {
    return this.https.post(
      `${environment.apiEndpoint}/market/propertymarket`,
      payloadObject,
      { headers: headers }
    );
  }

  getMarketPropertyList(payloadObject: marketlistinfo) {
    return this.https.get(
      `${environment.apiEndpoint}/market/propertylist/${encodeURIComponent(payloadObject.Market_Id)}`,
      { headers: headers }
    );
  }

  createCompset(payloadObject: createCompset) {
    return this.https.post(
      `${environment.apiEndpoint}/compset/create`,
      payloadObject,
      { headers: headers }
    );
  }

  updateCompset(payloadObject: updateCompset) {
    return this.https.put(
      `${environment.apiEndpoint}/compset/update`,
      payloadObject,
      { headers: headers }
    );
  }

  getCompsetProprtyDetails(payloadObject: any) {
    return this.https.get(
      `${environment.apiEndpoint}/compset/propertydetails/${encodeURIComponent(payloadObject.Compset_Id)}`,
      { headers: headers }
    );
  }
  deleteFloorplan(payloadObject:any){
    const options = {
      body: {
        Floorplan_Name: payloadObject.Flooplan_Name,
        Floorplan_Id: payloadObject.Floorplan_Id,
        LeaseTerm: payloadObject.LeaseTerm,       
        Property_Id: payloadObject.Property_Id,
        WeekEndingDates: payloadObject.WeekEndingDates,      }
    }
    return this.https.delete(
      `${environment.apiEndpoint}/market/deletefloorplan`,
      options);
  }
}


type leaseinfo = {
  Market_Id: number;
  WeekEndingDate: string;
  Is_Market_Level: string;
  LeaseTerm: string
};

type compsetslist = {
  Operator_Id: string,
  Market_Id?: string
};

class compsetInfo {
  Operator_Id: string = '';
  Compset_Id: any
  WeekEndingDate: string = '';
  Is_Compset_Level: string = '';
  LeaseTerm: string = '';

  equals(other: compsetInfo | any): boolean {
    return this.Operator_Id == other.Operator_Id
      && this.Compset_Id == other.Compset_Id
      && this.WeekEndingDate == other.WeekEndingDate
      && this.Is_Compset_Level == other.Is_Compset_Level
      && this.LeaseTerm == other.LeaseTerm
  }
}

type marketUnitInfor = {
  Market_Id?: any,
  WeekEndingDate?: string,
  Is_Market_Level: string,
  LeaseTerm: string
}

type compsetUnitInfor = {
  Operator_Id: string,
  Compset_Id?: any,
  WeekEndingDate: string,
  Is_Compset_Level: number,
  LeaseTerm: string,
}

type operatorlease = {
  User_Id: string;
}
type operatorlistall = {
  Operator_Id: string;
}
type leasetrend = {
  LeaseTerm: string,
  Compset_Id: string
}
type marketleasetrend = {
  Operator_Id: string
  Lease_Term: string;
  Market_Id: string;

}

type propertyinfo = {
  Property_Id: string;
  LeaseTerm: string
  WeekEndingDate: string;
  isClientProperty: number;
}

type floorplaninfo = {
  Property_Id: string;
  LeaseTerm: string;
  WeekEndingDates: string

}

type updatefloorplaninfo = {
  property_id: string;
  floorplan_name: string;
  lease_term: string;
  week_ending_date: string;
  no_of_units: string;
  promotions: string;
  floorplan_Type: string;
}

type updatefloorplandetail = {
  floorplans: floorplans[]
}

type floorplans = {
  property_id: string;
  lease_term: string,
  week_ending_date: string,
  floorplan_Beds: string,
  floorplan_Baths: string,
  floorplan_Bed_Count: string,
  occupancy: string,
  prelease: string,
  floorplan_rate: string,
  no_of_units: string,
  floorplan_Type: string,
  special_name: string,
  special_value: string,
  special_frequency: string,

}

type floorplandetail = {
  floorplans: createfloorplan[]
}

type createfloorplan = {
  property_id: number,
  floorplan_name: string,
  lease_term: string,
  week_ending_date: string,
  floorplan_Beds: string,
  floorplan_Baths: string,
  floorplan_Bed_Count: string,
  no_of_units: string,
  floorplan_Type: string,
  occupancy: string,
  prelease: string,
  floorplan_Rate: string,
  last_Updated_On: string,
  special_name: string,
  special_value: string,
  special_frequency: string
}

type updatepropertydetail = {
  property_Id: string,
  property_Name: string,
  owner: string,
  management_Company_Id: string,
  distToCampus: string,
  appFee: string,
  adminFee: string,
  deposit: string,
  parking: string,
  isPetFriendly: string,
  petFee: string,
  petDeposit: string,
  petRent: string,
  picBase64: string,
  picExtn: string,
  special_name: string,
  special_value: string,
  special_frequency: string,
  week_ending_date: string,
  isClientProperty: number,
}

type updateamenities = {
  property_id: string,
  amenity_ids: string
}

type createropertydetail = {
  marketId: string,
  property_Name: string,
  address: string,
  city: string,
  state: string,
  region: string,
  postal_Code: string,
  owner: string,
  management_Company_Id: string,
  distToCampus: string,
  appFee: string,
  adminFee: string,
  deposit: string,
  parking: string,
  isPetFriendly: string,
  petFee: string,
  petDeposit: string,
  petRent: string,
  picBase64: string,
  picExtn: string,
  lease_term: string
}
type marketbypropertid = {
  property_id: string,
  market_type: string
}

type marketlistinfo = {
  Market_Id: string
}

type createCompset = {
  Compset_Name: string,
  Market_Id: string,
  Operator_Id: string,
  Subjective_Property_Id: string,
  Comptative_Property_Ids: string
}

type updateCompset = {
  Compset_Id: string,
  Compset_Name: string,
  MarketId: string,
  OperatorId: string,
  Subjective_Property_Id: string,
  Comptative_Property_Ids: string
}
type occupancy = {
  Property_Id: string,
  LeaseTerm: string,
  WeekEndingDates: string,



}
type updateoccupancy = {
  property_id: string,
  lease_term: string,
  week_ending_date: string,
  prelease: string,
  occupancy: string

}
