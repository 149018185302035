import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }
  user:any=new BehaviorSubject(this.userInfo);
  set userInfo(value){
    this.user.next(value)
  }
  get userInfo(){
   return this.user;
  }
  operator:any=new BehaviorSubject(this.selectedOperator);
  set selectedOperator(value){
    this.operator.next(value)
  }
  get selectedOperator(){
   return this.operator;
  }
  marketMasterData:any=new BehaviorSubject(this.marketData);
  set marketData(value){
    this.marketMasterData.next(value)
  }
  get marketData(){
   return this.marketMasterData;
  }
  private leaseTextSource = new BehaviorSubject('');
		leaseSearchText = this.leaseTextSource.asObservable();
	  	  
		changeLeaseText(message: string) {
		  this.leaseTextSource.next(message)
		}
    private leaseFilterSource = new BehaviorSubject('');
		leaseFilterText = this.leaseFilterSource.asObservable();
	  	  
		changeLeaseFilter(message: string) {
		  this.leaseFilterSource.next(message)
		}

    private leaseRowData = new BehaviorSubject('');
		leaseCount = this.leaseRowData.asObservable();
	  	  
	setLeaseCount(message: string) {
		  this.leaseRowData.next(message)
		}
   
}
