<div class="sidebar">
  <div class="left-nav-container ">
    <div class="left-nav-top">
      <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
        <li *ngFor="let item of mapMenuList;let i =index" [ngClass]="{'in-active':item.active}">
          <div id="leaseNav" (click)="onMenuClick(item)">
            <a [tooltip]="item.name" placement="right" container="body" [routerLinkActiveOptions]="{exact: true}"
              routerLink="{{ item.routerLink }}" routerLinkActive="active-link active menu">
              <i class="{{ item.icon }}" aria-hidden="false"></i>
            </a>
          </div>
          <ul *ngIf="item.active">
            <li [ngClass]="{'in-active':item.active}" *ngFor="let item1 of item.subMenu">
              <div id="leaseNav">
                <a [tooltip]="item1.name" placement="right" container="body" routerLink="{{ item1.routerLink }}"
                  routerLinkActive="active-link active" [routerLinkActiveOptions]="{exact: true}">
                  <i class="{{ item1.icon }}" aria-hidden="false"></i>
                </a>
              </div>
            </li>
          </ul>
          <div style="border-bottom: 1px solid #DEDEDE;margin: 4px;"></div>
        </li>
      </ul>
    </div>
    <div class="left-nav-bottom">
      <div class="d-flex justify-content-center flex-column align-items-center">
        <a tooltip="Reports" placement="right" container="body" routerLink="leasing/report" *ngIf="isReport"
          routerLinkActive="active-link">
          <i class="icon icon-report" aria-hidden="false"></i>
        </a>
        <a tooltip="Help" placement="right" container="body" routerLink="/help-support" routerLinkActive="active-link">
          <i class="icon icon-help" aria-hidden="false"></i>
        </a>
        <a tooltip="Settings" *ngIf="roleList.includes('SHSuperAdmin')" placement="right" container="body"
          routerLink="/settings" routerLinkActive="active-link">
          <i class="icon icon-settings" aria-hidden="false"></i>
        </a>
        <a tooltip="" placement="right" class="py-0">
          <button (click)="opensidebar()" class="d-flex justify-content-center border-0 w52 ">
            <i class="icon icon-expand" aria-hidden="false"></i>
          </button>
        </a>
      </div>
    </div>
  </div>
  <div class="left-nav-container" [ngClass]="show? 'secondstate' : 'first-state'">
    <div class="left-nav-top">
      <div id="">
        <ul class="w-100  flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
          <li *ngFor="let item of mapMenuList;let i =index" [ngClass]="{'in-active':item.active}">
            <a routerLinkActive="active-link active menu " (click)="onMenuClick(item)"
              routerLink="{{ item.routerLink }}" [routerLinkActiveOptions]="{exact: true}"
              class="nav-link px-0 align-middle drop-down-icon">
              <span class="w52 h36 d-flex justify-content-center"><i [class]="item.icon"></i></span>
              <span class=" d-none d-sm-inline main-menu-title">{{item.name}}</span> </a>
            <ul class="collapse show " *ngIf="item.active" id="leaseNav">
              <li class="w-100" *ngFor="let item1 of item.subMenu">
                <a placement="right" container="body" routerLink="{{ item1.routerLink }}"
                  routerLinkActive="active-link">
                  <span class="px-2"> <i class="{{ item1.icon }}" aria-hidden="false"></i></span>
                  <span class=" ">{{ item1.name }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="left-nav-bottom">
      <div class="hr-line"></div>
      <a  placement="right" container="body" routerLink="leasing/report" routerLinkActive="active-link" *ngIf="isReport">
        <span class="w52 h36 d-flex justify-content-center"><i class="icon icon-report"
          aria-hidden="false"></i></span>
      <span class="main-menu-title">Reports</span>
      </a>
      <a tooltip="" placement="right" container="body" routerLink="/help-support" routerLinkActive="active-link">
        <span class="w52 h36 d-flex justify-content-center"><i class="icon icon-help" aria-hidden="false"></i></span>
        <span class="main-menu-title">Help</span>
      </a>
      <a tooltip=""  placement="right" container="body" routerLink="/settings" *ngIf="roleList.includes('SHSuperAdmin')"
        routerLinkActive="active-link">
        <span class="w52 h36 d-flex justify-content-center"><i class="icon icon-settings"
            aria-hidden="false"></i></span>
        <span class="main-menu-title">Settings</span>
      </a>
      <button (click)="opensidebar()" class="sidebar-state-btn">
        <i class="icon icon-menu" aria-hidden="false"></i>
      </button>
    </div>
  </div>

</div>