import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, Event, } from '@angular/router';
import { SharedService } from '@app/services/shared.service';
import { UserService } from '@app/services/user.service';
import { AuthService } from '@auth0/auth0-angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-leftnav',
  templateUrl: './leftnav.component.html',
  styleUrls: ['./leftnav.component.scss'],
})
export class LeftnavComponent implements OnInit, OnDestroy {
  list: any = [];
  show: boolean = false;
  isReport = true;
  leftMenuList: any = [
    {
      id: 1,
      name: 'Leasing',
      icon: 'icon icon-lease',
      routerLink: '/leasing',
      tooltip: 'leasing',
      active: true,
      subMenu: [
        {
          id: 1,
          name: 'Portfolios',
          icon: 'icon icon-business',
          routerLink: '/leasing/portfolio',
          tooltip: 'portfolios',
        },
        {
          id: 2,
          name: 'Properties',
          icon: 'icon icon-home',
          routerLink: '/leasing/property',
          tooltip: 'properties',
        },
        {
          id: 3,
          name: 'Leases',
          icon: 'icon icon-contract',
          routerLink: '/leasing/lease',
          tooltip: 'Leases',
        },
      ]

    },
    {
      id: 2,
      name: 'Leasing',
      icon: 'icon icon-lease',
      routerLink: '/leasing/conventionaldashboard',
      tooltip: 'leasing',
      active: true,
      isConvential: true,
      subMenu: [
        {
          id: 1,
          name: 'Portfolios',
          icon: 'icon icon-business',
          routerLink: '/leasing/conventionalportfolio',
          tooltip: 'portfolios',
        },
        {
          id: 2,
          name: 'Properties',
          icon: 'icon icon-home',
          routerLink: '/leasing/conventionalproperty',
          tooltip: 'properties',
        },
        {
          id: 3,
          name: 'Leases',
          icon: 'icon icon-contract',
          routerLink: '/leasing/conventionallease',
          tooltip: 'Leases',
        },
      ]

    },
    {
      id: 3,
      name: 'Market Survey',
      icon: 'icon icon-market',
      routerLink: '//market-overview',
      tooltip: 'Market Survey',
      active: false,
      subMenu: [{
        name: 'Markets',
        icon: 'icon icon-marketplace',
        routerLink: '/market-overview/markets',
        tooltip: 'Markets',
      },
      {
        name: 'Compsets',
        icon: 'icon icon-compset',
        routerLink: '/market-overview/compset',
        tooltip: 'Compsets',
      },
      {
        name: 'Properties',
        icon: 'icon icon-properties',
        routerLink: '/market-overview/market-overview-property',
        tooltip: 'Properties',
      },]
    }
  ]



  constructor(private auth: AuthService, private userService: UserService, private router: Router, private sharedService: SharedService,) {
    this.loadMenuList(1);
  }
  roleList: any = [];
  subscription: Subscription;
  subscription1: Subscription;

  ngOnInit(): void {
    this.auth.user$.subscribe((res: any) => {
      if (res) {
        res.isUserViewOnly = res['HexIndex/roles'].includes('SHViewOnlyUser');
        this.userService.userInfo = res;
        this.roleList = res['HexIndex/roles'];
      }
    });
    this.subscription1?.unsubscribe()
    this.subscription1 = this.sharedService.WebType.subscribe((res: any) => {
      if (res == 1) {
        this.loadMenuList(1);
      } else {
        this.loadMenuList('');
      }
    });
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('market')) {
          this.onMenuClick(this.mapMenuList[1]);
        }
      }
    })
    if (this.router.url.includes('market')) {
      this.onMenuClick(this.mapMenuList[1]);
    }
    this.subscription?.unsubscribe()
    this.subscription = this.userService.selectedOperator.subscribe((res: any) => {
      if (res) {
        if (res?.selectedoperator?.Operator_Id !== '18371453-902e-11ee-b225-000d3a53b49c' && this.mapMenuList[0].id == 2) {
          this.loadMenuList(1);
        }
      }
    })

  }
  opensidebar() {
    this.show = !this.show
    this.sharedService.sideBar.next(this.show);
  }
  onMenuClick(item: any) {
    this.isReport = item.id == 3 ? false : true
    this.mapMenuList.map((nav: any) => {
      if (nav.id == item.id) {
        nav.active = true
      } else {
        nav.active = false
      }
    });
  }
  mapMenuList: any;
  loadMenuList(type: any) {
    this.mapMenuList = [];
    if (type == 1) {
      this.leftMenuList.filter((item: any) => {
        if (!item.isConvential) {
          this.mapMenuList.push(item)
        } else if (item.id == 3) {
          this.mapMenuList.push(item);
        }
      })
    } else {
      this.leftMenuList.filter((item: any) => {
        if (item.isConvential) {
          this.mapMenuList.push(item)
          // }else if(item.id==3){
          //   this.mapMenuList.push(item);
        }
      })
    }

  }
  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscription1?.unsubscribe()

  }
}

