import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeasingApiService {

  private http = inject(HttpClient)

  getPropertyList() {
    return this.http.get<any[]>(
      `${environment.apiEndpoint}/properties/userentitledproperties`
    );
  }
  getConventionalPropertyList() {
    return this.http.get<any[]>(
      `${environment.apiEndpoint}/properties/list`
    );
  }
  getConventionalPortfoliobyId(id:any) {
    return this.http.get<any[]>(
      `${environment.apiEndpoint}/conventional/portfolio/${id}`
    );
  }
  updatePortfolio(payloadObject:any){
    return this.http.put(
      `${environment.apiEndpoint}/conventional/portfolio`,
      payloadObject,
    );
    
  }
  getConvetialUserLeaseTerm(){
    return this.http.get<any[]>(`${environment.apiEndpoint}/conventional/lease/userleaseterm`);
  }
  getLeaseTermList() {
    return this.http.get(`${environment.apiEndpoint}/lease/userleaseterm`);
  }

  getPortfolioList() {
    return this.http.get(`${environment.apiEndpoint}/portfolio`);
  }
  deletePortfolioListById(id:number) {
    const options = {
      body: {
        Portfolio_Id: id
      }
    }
    return this.http.delete(`${environment.apiEndpoint}/portfolio`, options);
  }

  getUserloginHistory(login_status:string) {
    return this.http.get(`${environment.apiEndpoint}/user/loginhistory/${encodeURIComponent(login_status)}`);
  }

  getPortfolioMatrix(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/portfolio/matrics`,
      payloadObject,
    );
  }

  getPropertyMatrix(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/properties/metrics`,
      payloadObject,
    );
  }

  getEntitledLease(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/lease/userentitledleases`,
      payloadObject,
    );
  }

  getPropertyRollUpMatrix(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/properties/rollupmatrics`,
      payloadObject,
    );
  }
  getcONPropertyRollUpMatrix(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/conventional/portfolio/propertyleases`,
      payloadObject,
    );
  }
  getWeeklyPreleaseTrend(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/lease/weeklypreleasetrends`,
      payloadObject,
    );
  }

  getRentGrowthMetrics(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/floorplan/rentgrowthmatrix`,
      payloadObject,
    );
  }

  getRevenueDetail(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/floorplan/revenuedetails`,
      payloadObject,
    );
  }

  getRentGrowth(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/floorplan/rentgrowth`,
      payloadObject,
    );
  }

  getPropertyUnitTypeInfo(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/properties/unittypeinfo`,
      payloadObject,
    );
  }

  getPortfolioUnitTypeInfo(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/portfolio/unittypeinfo`,
      payloadObject,
    );
  }

  setUserLoginHistory(data:any){
    return this.http.post(
      `${environment.apiEndpoint}/user/loginhistory`,
      data,
    );
  }
getLeaseCount(payloadObject:any){
  return this.http.post(
    `${environment.apiEndpoint}/properties/leaseCount`,
    payloadObject,
  );
  
}
  // getipAddress(){
  //   return this.http.get("http://api.ipify.org/?format=json");
  // }
  getConventionalPortfolioSummary(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/conventional/portfolio/portfoliosummary`,
      payloadObject,
    );
  }
  deleteConventionalPortfolioById(Id: any) {
    const options = {
      body: {
        Portfolio_Id: Id
      }
    }
    return this.http.delete(`${environment.apiEndpoint}/conventional/portfolio`, options);
  }
  getConventionalLeases(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/conventional/lease/userentitledleases`,
      payloadObject,
    );
  }
  
  getPropertyOccupancyratio(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/properties/occupancyratio`,
      payloadObject,
    );
  }
  getConNewvsRenewal(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/conventional/lease/newvsrenewal`,
      payloadObject,
    );
  }
 addConPortfolio(payloadObject: any) {
    return this.http.post(
      `${environment.apiEndpoint}/conventional/portfolio`,
      payloadObject,
    );
  }
  

}
