import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { LoaderService } from './loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(private loader: LoaderService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {


   this.totalRequests++;
    // this.loader.show();
    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        console.log(this.totalRequests--);
        if (this.totalRequests == 0) {
        this.loader.hide();
        }
      }
      )
    );
  }
  }

 
// }