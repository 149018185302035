<div class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
</div>
<div class="modal-body portfolio-modalbody">
    <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
        <div class="row mx-2">
            <div class="col-md-12">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home"
                            type="button" role="tab" aria-controls="home" aria-selected="true">Basic Details</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"
                            type="button" role="tab" aria-controls="profile" aria-selected="false">Role &
                            Access</button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div class="row">
                            <div class="col-md-6 ">
                                <label for="name" class="form-label form-font">First Name</label>
                                <input type="text" id="name" formControlName="name" class="form-control"
                                    [ngClass]="{ 'is-invalid': userForm.get('name')?.invalid && userForm.get('name')?.touched }"
                                    placeholder="Enter first name" />
                                <div *ngIf="userForm.get('name')?.invalid && userForm.get('name')?.touched"
                                    class="invalid-feedback">
                                    First Name is required.
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="name" class="form-label form-font">Last Name</label>
                                <input type="text" id="name" formControlName="lastname" class="form-control"
                                    [ngClass]="{ 'is-invalid': userForm.get('lastname')?.invalid && userForm.get('lastname')?.touched }"
                                    placeholder="Enter last name" />
                                <div *ngIf="userForm.get('lastname')?.invalid && userForm.get('lastname')?.touched"
                                    class="invalid-feedback">
                                    Last Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-md-6">
                                <label for="email" class="form-label form-font">Email</label>
                                <input type="email" id="email" formControlName="email" class="form-control"
                                    [ngClass]="{ 'is-invalid': userForm.get('email')?.invalid && userForm.get('email')?.touched }"
                                    placeholder="Enter email" required />
                                <div *ngIf="userForm.get('email')?.invalid && userForm.get('email')?.touched"
                                    class="invalid-feedback">
                                    Please enter a valid email.
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="phone" class="form-label form-font">Phone</label>
                                <input type="tel" id="phone" formControlName="phone" class="form-control"
                                    [ngClass]="{ 'is-invalid': userForm.get('phone')?.invalid && userForm.get('phone')?.touched }"
                                    placeholder="Enter phone number" />
                                <div *ngIf="userForm.get('phone')?.invalid && userForm.get('phone')?.touched"
                                    class="invalid-feedback">
                                    Please enter a valid phone.
                                </div>
                            </div>
                        </div>
                        <div class="row last-row-child">
                            <div class="col-md-6">
                                <label for="name" class="form-label form-font">Title</label>
                                <input type="text" id="name" formControlName="title" class="form-control"
                                    [ngClass]="{ 'is-invalid': userForm.get('title')?.invalid && userForm.get('title')?.touched }"
                                    placeholder="Enter Title" />
                            </div>
                        </div>

                    </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="name" class="form-label form-font">Company</label>
                                        <select id="company" formControlName="company" class="form-control form-select"
                                            (change)="onSelectOperator($event)"
                                            [ngClass]="{ 'is-invalid': userForm.get('company')?.invalid && userForm.get('company')?.touched }"
                                            required>
                                            <option value="">Select</option>
                                            <option *ngFor="let company of companyList" [ngValue]="company.Operator_Id">
                                                {{company.Operator_Name}}</option>
                                        </select>
                                        <div *ngIf="userForm.get('company')?.invalid && userForm.get('company')?.touched"
                                            class="invalid-feedback">
                                            Company is required.
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="roletype" class="form-label form-font">Role</label><br>
                                        <select id="roletype" formControlName="roletype"
                                            class="form-control form-select" (change)="onRoleChange($event)"
                                            [ngClass]="{ 'is-invalid': userForm.get('roletype')?.invalid && userForm.get('roletype')?.touched }"
                                            required>
                                            <option value="">Select</option>
                                            <option *ngFor="let rolename of role" [ngValue]="rolename">
                                                {{rolename.role_name}}
                                            </option>
                                        </select>
                                        <div *ngIf="userForm.get('roletype')?.invalid && userForm.get('roletype')?.touched"
                                            class="invalid-feedback">
                                            Role is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="my-2 col-md-12">
                                <div class="row">
                                    <div class="col-md-12 multiselect-common-style "
                                        [ngClass]="portfolioList.length > 1 ? 'multi-dropdown-style':null">
                                        <label for="portfolio" class="form-label form-font">Portfolio</label><br>
                                        <ng-multiselect-dropdown id="portfolio" name="portfolio"
                                            [placeholder]="'Select'" [data]="portfolioList" formControlName="portfolio"
                                            [disabled]="disabled1" (onSelect)="onSelectPortfolio()"
                                            (onDeSelect)="onSelectPortfolio()" [settings]="dropdownSettings1"
                                            [class.is-invalid]="userForm.get('portfolio')?.invalid">
                                        </ng-multiselect-dropdown>
                                        <div *ngIf="userForm.get('portfolio')?.invalid " class="invalid-feedback">
                                            Portfolio is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 last-row-child">
                                <div class="row">
                                    <div class=" col-md-12 multiselect-common-style"
                                        [ngClass]="propertyList.length > 1 ? 'multi-dropdown-style':null"
                                        *ngIf="isShowProperty">
                                        <label for="property" class="form-label form-font">Property</label><br>
                                        <ng-multiselect-dropdown id="property" name="property" [placeholder]="'Select'"
                                            [settings]="dropdownSettings" [data]="propertyList"
                                            formControlName="property" [disabled]="disabled" defaultOpen="false"
                                            [class.is-invalid]="userForm.get('property')?.invalid">
                                        </ng-multiselect-dropdown>
                                        <div *ngIf="userForm.get('property')?.invalid " class="invalid-feedback">
                                            Property is required.
                                        </div>
                                    </div>
                                    <div class=" col-md-12 multiselect-common-style"
                                        [ngClass]="propertyList.length > 1 ? 'multi-dropdown-style':null"
                                        *ngIf="!isShowProperty">
                                        <label for="property" class="form-label form-font">Property</label><br>
                                        <ng-multiselect-dropdown id="property" name="property" [placeholder]="'Select'"
                                            [data]="propertyList" formControlName="property" [disabled]="disabled"
                                            defaultOpen="false" [settings]="dropdownSettings"
                                            [class.is-invalid]="userForm.get('property')?.invalid">
                                        </ng-multiselect-dropdown>
                                        <div *ngIf="userForm.get('property')?.invalid " class="invalid-feedback">
                                            Property is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-cancel" (click)="closeModal()">
                Cancel
            </button>
            <button type="button" class="btn btn-submit" type="submit" [disabled]="userForm.invalid">
                {{ isEdit ? "Update" : "Submit" }}
            </button>
        </div>
    </form>
</div>